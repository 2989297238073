import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { Observable, filter } from 'rxjs';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';

/**
 * Use this to check if you are runing inside office enviroment
 * Office.initialize will never be called on the web
 *
 * As per documentation
 * https://learn.microsoft.com/en-us/javascript/api/office?view=common-js-preview#office-office-onready-function(1)
 */
function isRunInOfficeContext() {
  return new Observable<boolean>((observer) => {
    Office.onReady((info) => {
      if (info.host === null && info.platform === null) {
        // Running outside of the office
        observer.next(false);
        observer.complete();
      } else {
        console.log(`Office host: ${info.host}`);
        console.log(`Office Platform: ${info.platform}`);
        observer.next(true);
        observer.complete();
      }
    });
  });
}

if (environment.production) {
  enableProdMode();
}

const bootstrapAngular = bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);

Office.initialize = (reason: Office.InitializationReason) => {
  console.log(`Office initalization reason: ${reason.toString()}`);
  bootstrapAngular;
};

isRunInOfficeContext()
  .pipe(filter((isOfficeContext) => !isOfficeContext))
  .subscribe(() => {
    bootstrapAngular;
  });
